<template>
    <div v-loading="$wait.is('loading.services_types')">
        <div class="flex justify-between border-b border-solid border-gray-200 pb-2 mb-5">
            <h1>{{ $t('clients.total_amount_of_damages') }}</h1>
        </div>
        <div class="flex justify-center mb-6">
            <p class="text-3xl">
                {{ intToMoneyFormat(damagesData) }}
            </p>
        </div>
    </div>
</template>
<script>

export default {

    props: {
        damagesData: {
            type:    Number,
            default: 0,
        },
    },
};
</script>
